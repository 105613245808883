<template>
  <div class="hero position-relative w-100 pt-sm-4 pt-3 pb-sm-4 pb-3 d-flex">
    <div class="container text-center flex-column align-items-center py-5 align-self-center" :class="button ? 'mb-3' : 'mb-0'">
      <h1 class="text-center hero-header font-weight-bold p-0 m-0 mb-2 my-auto">{{ heading }}</h1>
      <p class="mx-auto col-12 col-sm-10 col-md-8 col-lg-6 px-1">{{ subheading }}</p>
      <div class="mt-3" v-if="button">
        <!-- logged in / not pre-seller-->
        <div v-if="accessToken && !$store.getters.hasRole('ROLE_PRE_SELLER') && !$store.getters.hasRole('ROLE_SELLER')">
          <b-button class="btn-primary font-weight-bold px-5 py-2 d-block d-sm-inline-flex w-100" :to="{ name: 'seller-welcome' }">{{ $t('become_seller_button_signup') }}</b-button>
        </div>
        <!-- logged in / pre-seller  -->
        <div v-else-if="accessToken && $store.getters.hasRole('ROLE_PRE_SELLER') && !$store.state.user.verification">
          <b-button class="btn-primary font-weight-bold px-5 py-2 d-block d-sm-inline-flex w-100" :to="{ name: 'connecting-etn' }">{{ $t('become_seller_button_signup') }}</b-button>
        </div>
        <!-- logged in / pre-seller / need email confirmation  -->
        <div v-else-if="accessToken && $store.getters.hasRole('ROLE_PRE_SELLER') && $store.state.user.verification === 1 && !$store.state.user.emailConfirmed">
          <b-button class="btn-primary font-weight-bold px-5 py-2 d-block d-sm-inline-flex w-100" :to="{ name: 'settings' }">{{ $t('become_seller_button_email') }}</b-button>
        </div>
        <!-- not logged in TO SIGNUP -->
        <div v-else-if="!accessToken">
          <b-button class="btn-primary font-weight-bold px-5 py-2 d-block d-sm-inline-flex w-100" v-on:click="loadSignUp()">{{ $t('become_seller_button_signup') }}</b-button>
        </div>
        <!-- logged in / is seller TO CREATE TASK-->
        <div v-else>
          <b-button class="btn-primary font-weight-bold px-5 py-2 d-block d-sm-inline-flex w-100" :to="{ name: 'createTask' }">{{ $t('become_seller_button_task') }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroCurved',
  props: {
    id: String,
    heading: String,
    subheading: String,
    button: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      accessToken: localStorage.getItem('accessToken')
    }
  },
  methods: {
    loadSignUp () {
      this.$bvModal.show('sellerSignUpModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  .hero {
    color: white;
    background-size: cover;
    background-position: center top;
    border-bottom-left-radius: 100% 20%;
    border-bottom-right-radius: 100% 20%;
    min-height: 300px;
    h1 {
      line-height: 2.5rem;
    }
    h1, p {
      text-shadow: $become-seller-text-shadow;
    }
    @include sm-up {
      .btn {
        width: auto !important;
      }
      height: 325px;
    }
  }
</style>
